import React, { useEffect, useRef, useState } from "react";
import {
  navbarLink,
  productCategory,
  ImportExport,
} from "../data/navbarCategory";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { FaTruckFast } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsBag } from "react-icons/bs";
import { Link } from "react-router-dom";
import MobileSidebar from "./MobileSidebar";
import MobileSearchbar from "./MobileSearchbar";
import { useNavigate } from "react-router-dom";

import useOnClickOutside from "../hooks/useOnClickOutside";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [isClickProduct, setIsClickProduct] = useState(false);
  const [isClickImpexp, setIsClickImpexp] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [windowSize, setwindowSize] = useState({});
  const navigate = useNavigate();
  const refProduct = useRef();
  const refImpexp = useRef();
  useOnClickOutside(refProduct, () => setIsClickProduct(false));
  useOnClickOutside(refImpexp, () => setIsClickImpexp(false));
  useEffect(() => {
    setwindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);
  useEffect(() => {
    if (showSidebar || showSearchbar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showSidebar, showSearchbar]);

  const searchHandler = (e) => {
    if (e.key === "Enter") {
      return navigate(
        `/search/${searchQuery.toLowerCase().split(" ").join("-")}`
      );
    }
  };
  return (
    <div className="flex flex-col shadow-lg pt-2">
      <div className="flex justify-between h-[85px] py- lg:px-10 px-4">
        <button
          className="text-xl font-bold lg:hidden block"
          onClick={() => {
            setShowSidebar(true);
          }}
        >
          <RxHamburgerMenu />
        </button>
        <MobileSidebar
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />
        <div className="flex lg:mx-1 justify-between items-center lg:my-2">
          <Link
            to={"/"}
            className="flex flex-col items-center justify-center mx-15"
          >
            <img
              src="https://res.cloudinary.com/dovdntcji/image/upload/v1694608053/logo-png_ecurep.png"
              className="lg:w-[150px] w-[100px] h-[70px]"
              alt=""
            />
            <p className="font-semibold mb-3">Bunaim International Pvt. Ltd</p>
          </Link>
        </div>
        <div className="lg:flex hidden gap-5 items-center">
          <div className="flex gap-4">
            <Link
              to={"/contact-us"}
              className="flex gap-1 underline items-center text-md justify-center hover:text-green-700"
            >
              Contact Us
            </Link>
            <Link
              to={"/our-presence"}
              className="flex gap-1 underline items-center text-md justify-center hover:text-green-700"
            >
              Our Presence
            </Link>
          </div>
          <div className="flex justify-center items-center gap-1">
            <input
              type="text"
              className="w-[200px] lg:block hidden outline-none px-2 py-1 h-8 rounded-md bg-gray-200"
              onKeyUp={searchHandler}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="flex flex-row items-center justify-center gap-3 text-2xl">
              <button
                className="lg:flex hidden"
                onClick={() => {
                  searchQuery.length > 0 &&
                    navigate(
                      `/search/${searchQuery
                        .toLowerCase()
                        .split(" ")
                        .join("-")}`
                    );
                }}
              >
                {<FiSearch />}
              </button>
            </div>
          </div>
        </div>
        <button
          className="lg:hidden text-lg"
          onClick={() => {
            setShowSearchbar(true);
          }}
        >
          <FiSearch />
        </button>
      </div>
      {/* category  */}
      <div className="hidden lg:flex gap-6 flex-wrap w-[85%] items-center my-2 justify-center text-black">
        {navbarLink.map((item, index) =>
          item.title === "Product" ? (
            <div className="group relative" key={index}>
              <p
                className="flex gap-1 cursor-pointer items-center text-md justify-center font-semibold hover:text-green-700"
                onClick={() => {
                  setIsClickProduct((prev) => !prev);
                }}
              >
                {item.title} <BiChevronDown />
              </p>

              {isClickProduct && (
                <div
                  ref={refProduct}
                  className="translate-x-[-40%] gap-3 top-9 shadow-lg  rounded-lg flex  transition-all duration-300 h-fit bg-white w-[1150px] absolute z-50"
                >
                  {productCategory.map((productItem, i) => (
                    <div
                      className="flex flex-col w-[19%] border-l gap-5"
                      key={i}
                    >
                      <p className="mt-3 mx-3 font-semibold text-md">
                        {productItem.category}
                      </p>
                      <div className="flex flex-col h-fit flex-wrap gap-2">
                        {productItem.subCategory.map((subCategory, ind) => (
                          <Link
                            key={ind}
                            to={`/category/${subCategory
                              .split(" ")
                              .join("-")
                              .toLowerCase()}`}
                          >
                            <button
                              className="hover:bg-gray-200 px-3 py-1 mx-3 rounded-md"
                              onClick={() => {
                                setIsClickProduct(false);
                              }}
                            >
                              {subCategory}
                            </button>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : item.title === "International Trades" ? (
            <div className="group relative" key={index}>
              <p
                className="flex gap-1 items-center cursor-pointer text-md justify-center font-semibold hover:text-green-700"
                onClick={() => {
                  setIsClickImpexp((prev) => !prev);
                }}
              >
                {item.title} <BiChevronDown />
              </p>

              {isClickImpexp && (
                <div
                  ref={refImpexp}
                  className="translate-x-[-20%] gap-3 top-9 shadow-lg rounded-lg flex  transition-all duration-300 bg-white w-[500px] h-fit absolute z-50"
                >
                  {ImportExport.map((type, i) => (
                    <div
                      className="flex flex-col justify-center  w-[48%] border-l gap-5"
                      key={i}
                    >
                      <p className="mt-3 mx-3 font-semibold text-md">
                        {type.category}
                      </p>
                      <div className="flex flex-col h-[250px] flex-wrap gap-2">
                        {type.subCategory.map((item, ind) => (
                          <Link
                            key={ind}
                            to={`/category/${item.link
                              .split(" ")
                              .join("-")
                              .toLowerCase()}`}
                          >
                            <button
                              className="hover:bg-gray-200 px-3 py-1 mx-3 rounded-md"
                              onClick={() => {
                                setIsClickImpexp(false);
                              }}
                            >
                              {item.title}
                            </button>
                          </Link>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div key={index}>
              <Link
                to={`${item?.link.split(" ").join("-").toLowerCase()}`}
                className="flex gap-1 items-center text-md justify-center font-semibold hover:text-green-700"
              >
                {item.title}
              </Link>
            </div>
          )
        )}
      </div>
      {windowSize.width < 1024 && (
        <MobileSearchbar
          showSearchbar={showSearchbar}
          setShowSearchbar={setShowSearchbar}
        />
      )}
    </div>
  );
};

export default Navbar;
