export const navbarLink = [
  // {
  //     category:"Nuts",
  //     subCategory:["Almonds","Cashews","Pistachios","Walnuts","Hazelnut","Brazil Nuts","Macadamia Nuts","Pecannuts","Pinenuts"]
  // },
  // {
  //     category:"Dried Fruits",
  //     subCategory:["Raisins","Anjeer","Apricots","Prunes","Kiwi","Pineapple","Papaya","Mango"]
  // },
  // {
  //     category:"Berries",
  //    subCategory:["Blueberries","Cranberries","Strawberries","Mulberries","Gojiberries"]
  // },
  // {
  //     category:"Dates",
  //     subCategory:["Omani","Queen Kalmi","King Medjoul","Arabian","Ajwa","Golden Barhi"]
  // },
  // {
  //     category:"Spices",
  //     subCategory:["Black-Pepper","Cardamom","Coriander","Cumin","Fenugreek","Ginger","Turmeric","Red-chilli"]
  // },
  // {
  //     category:"Mixes",
  //     subCategory:["Fitness Mix","Roasted Party Mix","Nuts & Berries Mix","Berries Mix","Champion Mix","Nutty Trail Mix","Seeds Mix"]
  // },
  //   {
  //     link:"Home",
  //     subLink:[]
  //   },
  //   {
  //     link:"About Us",
  //     subLink:[]
  //   },
  //   {
  //     link:"Product",
  //     subLink:[
  //         {
  //             category:"Dry Fruits",
  //             subCategory:["Almonds","Cashews","Pistachios","Walnuts","Hazelnut","Brazil Nuts","Macadamia Nuts","Pecannuts","Pinenuts"]
  //         },
  //         {
  //             category:"Spices"
  //         }

  //     ]
  //   },
  //   {
  //     category: "Bulk Order",
  //     subCategory: [],
  //   },
  //   {
  //     category: "Import & Export",
  //     subCategory: [],
  //   },
  //   {
  //     category: "Stores",
  //     subCategory: [],
  //   },
  {
    title:"Home",
    link:'/',
  },
  {
    title:"About",
    link:'/About Us',
  },
  {
    title:"International Trades",
    link:'/International Trades',
  },
  {
    title:"Domestic Trades",
    link:"/domestic trades"

  },
  {
    title:"Product",
    link:'/Product',
  },
  {
    title:"Order",
    link:'/Order',
  }, 
     
];
export const navbarLinkMobile = [
  {
    title:"Home",
    link:'/',
  },
  {
    title:"About",
    link:'/About Us',
  },
  {
    title:"International Trades",
    link:'/International Trades',
  },
  {
    title:"Domestic Trades",
    link:"/domestic trades"

  },
  {
    title:"Product",
    link:'/Product',
  },
  {
    title:"Order",
    link:'/Order',
  }, 
  {
    title:"Contact Us",
    link:'/contact-us'
  },
  {
    title:"Our Presence",
    link:'/our-presence'
  }

]
export const productCategory = [
  {
    category: "Dry Fruits and Nuts",
    subCategory: [
      "Almonds",
      "Cashews",
      "Pistachios",
      "Walnuts",
      "Raisins", 
      "Figs",
      "Apricots",
      "Pinenuts",
      "Dried dates",
    ],
  },
  {
    category: "Dates",
    subCategory: [
      "Kalmi safawi Dates",
      "Ajwa Dates",
      "Medjool Dates",
      "Amber Dates",
      "Mabroon Dates",
      "Sagai Dates", 
      "Rabbi Dates",
      "Kimia Dates",
    ],
  },
  {
    category: "Confectionary Items",
    subCategory: [
      "Candies",
      "Chocolates",
      "Baked Goods",
      "Chewing Gum",
      "Fudge",
      "Marshmallows", 
      "Nougat",
      "Taffy",
      "Jellies and Gelatin-based Candies",
      "Marzipan and Almond Paste",
      "Sugar Art",
      "Candy-coated Products",
      "Seasonal and Holiday Confections",
    ],
  },
  // {
  //   category: "Spices",
  //   subCategory: [
  //     "Turmeric",
  //     "Chilli",
  //     "Black-Pepper",
  //     "Cardamom",
  //     "Clove",
  //     "Coriander",
  //     "Cumin",
  //     "Fenugreek",
  //     "Cinnamon",
  //     "Nutmeg",
  //     "Ginger", 
  //     "Garlic",
  //     "Star"
  //   ],
  // },
  // {
  //   category: "Pulses",
  //   subCategory: [ 
  //     "Masoor-Green Lentis",
  //     "Masoor-Red Lentis",
  //     "Urad-Black Gram",
  //     "Toor-Pigeon Peas",
  //     "Moong-Mung Beans",
  //     "Chana-Chickpeas",
  //     "Matar-Green Peas" 
  //   ],
  // },
  {
    category: "Beverages ",
    subCategory: [ 
      "Energy Drinks",
      "Soft Drinks",
      "Milkshakes",
      "Juices",
    ],
  },
  // {
  //   category: "Rice",
  //   subCategory: [
  //     "Long Grain White Rice",
  //     "Parboiled Sona masoori",
  //     "Swarna Rice",
  //     "Broken Rice",
  //     "Basmati Rice", 
  //     "Jasmine Brown Rice", 
  //     "Sharbati Rice", 
  //   ],
  // },
];
export const ImportExport = [
  {
    category:"Export",
    subCategory: [
      // {
      //   title: "Spices",
      //   link: "spices",
      // },
      {
        title: "Dry Fruits and Nuts",
        link: "dry-fruits",
      },
      // {
      //   title: "Rice",
      //   link: "rice",
      // },
      // {
      //   title: "Pulses",
      //   link: "pulses",
      // },
      {
        title: "Other Food Items",
        link: "food items",
      },
    ],
  },
  {
    category:"Import",
    subCategory: [
      {
        title: "Dates",
        link: "dates",
      },
      {
        title: "Dry Fruits and Nuts",
        link: "Dry Fruits",
      },
      // {
      //   title: "Spices",
      //   link: "spices",
      // },
      {
        title: "Other Food Items",
        link: "food items",
      },
    ],
  },
];
