
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast';
 

function mailSender(formData,template_id) { 
   const Id = toast.loading("Loading")
    emailjs.send("service_g8t83ph",template_id,formData,"C_55lYZkpo-xjoq13").then(function(response) {
      toast.dismiss(Id);
      toast.success("Form Submitted Successfully")
        console.log('SUCCESS!', response.status, response.text);
     }, function(error) {
      toast.dismiss(Id);
      toast.error("Failed !! Try Again Later")
        console.log('FAILED...', error);
     });
}

export default mailSender