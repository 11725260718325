
import './App.css';
import Navbar from './components/Navbar';
import { Routes,Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer';
import ProductPage from './pages/ProductPage';
import CategoryPage from './pages/CategoryPage';
import SearchPage from './pages/SearchPage';
import Error from './pages/Error';
import AboutusPage from './pages/AboutusPage';
import BulkOrderPage from './pages/BulkOrderPage'; 
import { useEffect} from 'react'
import TermServicesPage from './pages/FooterPage/TermServicesPage';
import PrivacyPolicy from './pages/FooterPage/PrivacyPolicy';
import ShippingPolicy from './pages/FooterPage/ShippingPolicy';
import RetrunAndRefundPolicy from './pages/FooterPage/RetrunAndRefundPolicy';
import ContactUs from './pages/FooterPage/ContactUs';
import OurVision from './pages/FooterPage/OurVision';
import WhyWeExist from './pages/FooterPage/WhyWeExist';
import MobileFooter from './components/MobileFooter';
import OurPresencePage from './pages/OurPresencePage';
import LocalSupply from './pages/LocalSupply';
import Certificates from './pages/Certificates';

function App() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
 
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
     <> 
     <Navbar/>
    <MobileFooter/>
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/product/:productId' element={<ProductPage/>}/>
       <Route path='/category/:category' element={<CategoryPage/>}/>
       <Route path='/search/:searchQuery' element={<SearchPage/>}/>
       <Route path='/about-us' element={<AboutusPage/>}/>
       <Route path='/order' element={<BulkOrderPage/>}/> 
       <Route path='/terms-of-services' element={<TermServicesPage/>}/>
       <Route path='/privacy-policy' element={<PrivacyPolicy/>}/> 
       <Route path='/shipping-policy' element={<ShippingPolicy/>}/>
       <Route path='/our-presence' element={<OurPresencePage/>}/>
       <Route path='/return-&-refund-policy' element={<RetrunAndRefundPolicy/>}/> 
       <Route path='/contact-us' element={<ContactUs/>}/> 
       <Route path='/our-vision' element={<OurVision/>}/> 
       <Route path='/why-we-exist' element={<WhyWeExist/>}/> 
       <Route path='/domestic-trades' element={<LocalSupply/>}/> 
       <Route path='/certificates' element={<Certificates/>}/> 
       

       

       
       <Route path='*' element={<Error/>}/>
     </Routes>
    
     <Footer/>
     </>
  );
}

export default App;
