import React from 'react' 
import { Link} from 'react-router-dom'

const ProductNotFound = () => {

  return (
    <div className='flex flex-col items-center justify-center gap-10 w-full h-[550px]'>
        <p className='lg:text-4xl text-2xl font-bold'>Product Not Available</p>
        <Link to={"/"}  
        className='text-white rounded-lg font-semibold bg-green-800 py-2 px-5'
        >Continue Shopping</Link>
    </div>
  )
}

export default ProductNotFound