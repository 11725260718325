import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ product,isSmall=false,setShowSearchbar= ()=>{},setSearchQuery=()=>{} }) => {


  return (
    <div className={`${isSmall ? "w-[48%] mx-0 h-fit py-10":"mx-auto"} flex flex-wrap items-center rounded-lg shadow-2xl py-10 flex-col lg:mx-3 border-2 border-green-800 h-fit w-4/5 max-w-[300px] `}>
      <Link to={`/product/${product.id}`} onClick={()=>{setShowSearchbar(false); setSearchQuery("")}}>
        <div className={`w-[100%] ${isSmall ? "h-[125px] lg:h-[240px]": "h-[280px]"}  mx-auto`}>
          <img
            src={product.imagesUrl[0]}
            alt=""
            loading="eager"
            className={`${isSmall ? "lg:h-[240px] h-[135px]": "h-full"} hover:scale-90 mx-auto transition-all duration-300`}
          />
        </div>

        <p className="text-2xl lg:text-3xl mt-5 mb-2 text-center font-semibold">
          {product.title}
        </p>
        <p className="text-center text-xs">{product.subtitle}</p>
        {/* <p className="text-green-700 font-bold text-center text-2xl">₹ {product.price}</p> */}
      </Link>
      {/* <button className=" hover:bg-green-900 bg-green-800 mt-2 py-3  px-5 lg:px-7 text-xl text-white font-semibold rounded-lg transition-all duration-300">
        Add to Cart
      </button> */}
    </div>
  );
};

export default ProductCard;
