export const Products = [
    {
        id:"1",
        title:"Almond",
        subtitle:"WHOLE | UNSALTED | NON-GMO | RAW VEGAN FRIENDLY",
        desc:`Almonds are packed with healthy fats, protein, and fiber. They offer a mildly
        sweet, nutty flavor and are often eaten as a nutritious snack or used in various dishes, including
        desserts and salads.`,
        price:"200",
        weight:"300gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694447437/WHITE-BACKGROUND---FRONT---ALMOND_y4nugi.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527116/Super-market/WHITE-BACKGROUND---BACK---ALMOND_rfortd.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527209/Super-market/FULL-FRAME---DRY-FRUITS---Almond_z6sflp.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527366/Super-market/WHITE-BG---DRY-FRUITS---Almond_lbjxxy.jpg",],
        category:"Dry Fruits",
        subCategory:"Almonds",

    },
    {
        id:"2",
        title:"Cashews",
        subtitle:"WHOLE | UNSALTED |NON-GMO |RAW VEGAN FRIENDLY",
        desc:`Creamy and buttery in texture, cashews are a versatile dry fruit. They are used in
        both sweet and savory dishes, from creamy curries to cashew butter and desserts.`,
        price:"250",
        weight:"300gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694527805/Super-market/WHITE-BACKGROUND---FRONT---CASHEW_ayr93d.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527888/Super-market/WHITE-BACKGROUND---BACK---CASHEW_rqa8hj.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527934/Super-market/FULL-FRAME---DRY-FRUITS---Cashew_gk8gtj.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694527970/Super-market/WHITE-BG---DRY-FRUITS---Cashew_g9szsh.jpg"],
        serialNumber:"2",
        category:"Dry Fruits",
        subCategory:"Cashews",
    },
    {
        id:"3",
        title:"Pistachios",
        subtitle:"WHOLE | UNSALTED | NON-GMO |RAW VEGAN FRIENDLY",
        desc:`These vibrant green nuts have a unique flavor that’s slightly sweet and savory.
        Pistachios are enjoyed as a snack, used in baking, or incorporated into dishes like pilaf and ice
        cream`,
        price:"400",
        weight:"300 gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694529136/Super-market/WHITE-BACKGROUND---FRONT---PISTACHIO_dtesrz.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694529192/Super-market/WHITE-BACKGROUND---BACK---PISTACHIO_r7mb1t.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694529423/Super-market/FULL-FRAME---DRY-FRUITS---Pistachio_kzh9di.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694529484/Super-market/WHITE-BG---DRY-FRUITS---Pistachio_vdcpeo.jpg"],
        category:"Dry Fruits",
        subCategory:"Pistachios",

    },
    {
        id:"4",
        title:"Walnuts",
        subtitle:"WHOLE | UNSALTED | NON-GMO |RAW VEGAN FRIENDLY",
        desc:`* Walnuts are known for their rich, earthy taste and are a good source of omega-3
        fatty acids. They are commonly used in baking, added to salads, or eaten as a standalone snack.`,
        price:"400",
        weight:"300 gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694529964/Super-market/WHITE-BACKGROUND---FRONT---WALNUT_wyuy1j.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694531019/Super-market/WHITE-BACKGROUND---BACK---WALNUT_slicuk.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696598617/Bunaim/FULL-FRAME---DRY-FRUITS---Walnut_avlqh8.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694531276/Super-market/WHITE-BG---DRY-FRUITS---Walnut_cmctqo.jpg"],
        category:"Dry Fruits",
        subCategory:"Walnuts",
    },
    {
        id:"5",
        title:"Raisin",
        subtitle:"WHOLE | UNSALTED | NON-GMO |RAW VEGAN FRIENDLY",
        desc:`* Raisins are dried grapes that provide a natural sweetness to cereals, trail mixes, and
        baked goods. They are also used in Indian cuisine to add sweetness to various dishes, like
        biryanis and desserts.`,
        price:"180",
        weight:"300 gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694531733/Super-market/WHITE-BACKGROUND---FRONT---RAISIN_lzgxfh.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694531812/Super-market/WHITE-BACKGROUND---BACK---RAISIN_eufdqv.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694531928/Super-market/WHITE-BG---DRY-FRUITS---Raisin_s996v9.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532086/Super-market/FULL-FRAME---DRY-FRUITS---Raisin_dgb6uc.jpg"],
        category:"Dry Fruits",
        subCategory:"Raisins",
    },
    {
        id:"6",
        title:"Fig",
        subtitle:"WHOLE | UNSALTED | NON-GMO |RAW VEGAN FRIENDLY",
        desc:`Dried figs are sweet and slightly chewy, with a rich, honey-like taste. They are used in a
        variety of dishes, such as desserts, salads, and jams.`,
        price:"480",
        weight:"300 gm",
        imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694532209/Super-market/WHITE-BACKGROUND---FRONT---FIG_lm7zwb.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532292/Super-market/WHITE-BACKGROUND---BACK---FIG_yxohsx.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532338/Super-market/FULL-FRAME---DRY-FRUITS---Fig_i3xyoi.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532373/Super-market/WHITE-BG---DRY-FRUITS---Fig_p0azfl.jpg"],
        category:"Dry Fruits",
        subCategory:"Figs",
    },
    // {
    //     id:"7",
    //     title:"Black-Pepper",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:`Black pepper is used for its bold, pungent heat in a variety of
    //     Indian recipes, both ground and whole`,
    //     price:"50",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694532805/Super-market/WGITE-BG-FRONT---Black-Pepper_jkuvgd.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532875/Super-market/WGITE-BG-BACK---Black-Pepper_fmn5ts.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694532977/Super-market/PRODUCT-SHOT-WHITE-BG---Black-Pepper_nbsza4.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696232933/Bunaim/PRODUCT-SHOT-FULL-FRAME---Black-Pepper_bclp3u.jpg"],
    //     category:"Spices",
    //     subCategory:"Black-Pepper",
    //     uses:["Ground black pepper is used as a table seasoning.","Enhances the flavor of grilled meats, soups, and sauces.","Used in pickling and some spice blends."]
    // },
    // {
    //     id:"8",
    //     title:"Cardamom",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:`Cardamom pods, whether green or black, are prized for their aromatic
    //     and sweet-spicy notes. They’re used in both savory and sweet dishes.`,
    //     price:"`15",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694533156/Super-market/WGITE-BG-FRONT--Cardamom_djkan8.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233325/Bunaim/WGITE-BG-BACK---Cardamom_k8nqaz.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233208/Bunaim/PRODUCT-SHOT-WHITE-BG---Cardamom_cscq5m.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694533417/Super-market/PRODUCT-SHOT-FULL-FRAME---Cardamom_tvzgqt.jpg"],
    //     category:"Spices",
    //     subCategory:"Cardamom",
    //     uses:["Used in baking, particularly in Scandinavian pastries and Indian desserts.","Adds fragrance to rice dishes like biryani.","A key ingredient in chai tea and spice blends like garam masala."]   
    // },
    // {
    //     id:"9",
    //     title:"Coriander",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:`Both the seeds and fresh leaves (known as cilantro) are used in Indian
    //     cooking. Coriander imparts a citrusy, slightly sweet flavor and is essential in curry preparations.`,
    //     price:"30",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694533505/Super-market/WGITE-BG-FRONT---Coriander_vgtbb6.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694533521/Super-market/WGITE-BG-BACK--Coriander_tgb5l1.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233420/Bunaim/PRODUCT-SHOT-FULL-FRAME---Coriander_yxrcer.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694533556/Super-market/PRODUCT-SHOT-WHITE-BG---Coriander_dvubu3.jpg"],
    //     category:"Spices",
    //     subCategory:"Coriander",
    //     uses:["Fresh cilantro leaves are used as a garnish in salads, curries, and Mexican dishes.","Ground coriander seeds are used in spice mixes, pickles, and meat rubs.","Adds a refreshing, citrusy note to dishes."]   
    // },
    // {

    //     id:"10",
    //     title:"Cumin",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:`Cumin seeds add a warm, nutty flavor to dishes and are often used in spice
    //     blends like garam masala. They are a key ingredient in many Indian recipes.
    //     `,
    //     price:"20",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694533743/Super-market/WGITE-BG-FRONT---Cumin_gusapd.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694533758/Super-market/WGITE-BG-BACK---Cumin_tukssq.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694533784/Super-market/PRODUCT-SHOT-WHITE-BG---Cumin_w3glb1.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233519/Bunaim/PRODUCT-SHOT-FULL-FRAME---Cumin_jgx0ch.jpg"],
    //     category:"Spices",
    //     subCategory:"Cumin",
    //     uses:["Ground cumin is used in spice blends like curry powder.","Whole cumin seeds are often tempered in hot oil to flavor dishes like lentils and rice.","A key ingredient in Mexican and Tex-Mex cuisine, used in tacos, chili, and salsas."]
    // },
    // {
    //     id:"11",
    //     title:"Fenugreek",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:` Fenugreek leaves and seeds are used to lend a slightly bitter, maple-like
    //     taste to dishes, particularly in Indian bread and pickles.`,
    //     price:"60",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694534065/Super-market/WGITE-BG-FRONT---Fenugreek_lcom5p.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534078/Super-market/WGITE-BG-BACK---Fenugreek_rwc6xe.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233604/Bunaim/PRODUCT-SHOT-WHITE-BG---Fenugreek_y05zbs.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534099/Super-market/PRODUCT-SHOT-FULL-FRAME---Fenugreek_e3e0vx.jpg"],
    //     category:"Spices",
    //     subCategory:"Fenugreek",
    //     uses:["Fenugreek seeds are used in spice blends like curry powder and to make fenugreek tea.",
    //     "Fresh fenugreek leaves are used in Indian dishes, such as methi paratha and curries.",
    //     "Believed to have health benefits, particularly for digestion and lactation."]
    // },
    // {
    //     id:"12",
    //     title:"Ginger",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:``,
    //     price:"60",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694534275/Super-market/WGITE-BG-FRONT---Ginger_zombuo.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534291/Super-market/WGITE-BG-BACK---Ginger_qvptx0.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534316/Super-market/PRODUCT-SHOT-FULL-FRAME---Ginger_w6ngnb.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233710/Bunaim/PRODUCT-SHOT-WHITE-BG---Ginger_lc0j7t.jpg"],
    //     category:"Spices",
    //     subCategory:"Ginger",
    //     uses:["Used in Asian stir-fries, curries, and soups.",
    //     "Ginger tea is a popular beverage for its soothing qualities.",
    //     "Ground ginger is used in baking, particularly in gingerbread and ginger snaps."]
    
    // },
    // {
    //     id:"13",
    //     title:"Red Chilli",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:`Indian cuisine often features various types of dried red chilies, from
    //     mild to fiery, to provide heat and color to dishes.`,
    //     price:"30",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694534738/Super-market/WGITE-BG-FRONT---Red-Chilli_sugs2v.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534748/Super-market/WGITE-BG-BACK---Red-Chilli_foutyf.jpg", "https://res.cloudinary.com/dovdntcji/image/upload/v1694534781/Super-market/PRODUCT-SHOT-WHITE-BG---Red-Chilli_qv8mae.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233044/Bunaim/PRODUCT-SHOT-FULL-FRAME---Red-Chilli_bbghto.jpg"],
    //     category:"Spices",
    //     subCategory:"Chilli",
    //     uses:["Adds heat and flavor to chili con carne and Mexican dishes.",
    //     "Used in seasoning rubs for grilled meats and vegetables.",
    //     "A key ingredient in many Tex-Mex and Southwestern recipes."]
    // },
    // {
        
    //     id:"14",
    //     title:"Turmeric",
    //     subtitle:"100% NATURAL & ORGANIC",
    //     desc:` Known for its vibrant yellow color and earthy flavor, turmeric is a staple in
    //     Indian cuisine and boasts numerous health benefits.`,
    //     price:"20",
    //     weight:"100 gm",
    //     imagesUrl:["https://res.cloudinary.com/dovdntcji/image/upload/v1694534911/Super-market/WGITE-BG-FRONT---Turmeric_wcuien.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534927/Super-market/WGITE-BG-BACK---Turmeric_syeh7v.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1696233096/Bunaim/PRODUCT-SHOT-WHITE-BG--Turmeric_mlbsck.jpg","https://res.cloudinary.com/dovdntcji/image/upload/v1694534950/Super-market/PRODUCT-SHOT-FULL-FRAME---Turmeric_d2sdyz.jpg"],
    //     category:"Spices",
    //     subCategory:"Turmeric",
    //     uses:["Used as a spice in curry dishes.","Adds a vibrant yellow color to rice, soups, and stews.","Believed to have health benefits, so it’s used in turmeric tea and supplements."]
    // },
   {
    id:"15",
    title:"Kalmi Dates",
    subtitle:"100% NATURAL & ORGANIC",
    price:"150",
    weight:"100 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695202583/Bunaim/KALMI_q22tkw.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695202549/Bunaim/KALMI-DATES_aypnva.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695202615/Bunaim/KALMI-BACK_blcnks.jpg"],
    category:"Dates",
    subCategory:"Kalmi safawi Dates",
   }
   ,
   {
    id:"16",
    title:"Ajwa Dates",
    subtitle:"100% NATURAL & ORGANIC",
    price:"250",
    weight:"100 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203017/Bunaim/AJWA_rvxoon.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203006/Bunaim/AJWA-DATES_cedhzj.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695202875/Bunaim/AJWA-BACK_r6hkpw.jpg"],
    category:"Dates",
    subCategory:"Ajwa Dates",
   },
   {
    id:"17",
    title:"Sagai Dates",
    subtitle:"100% NATURAL & ORGANIC",
    price:"90",
    weight:"100 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203196/Bunaim/SAGAI_avnvzk.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203187/Bunaim/SAGAI-DATES_zdbd2g.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203226/Bunaim/SAGAI-BACK_iei45q.jpg"],
    category:"Dates",
    subCategory:"Sagai Dates",
   },
   {
    id:"18",
    title:"Kimia Dates",
    subtitle:"100% NATURAL & ORGANIC",
    price:"110",
    weight:"100 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203631/Bunaim/KIMIA_pvcc6e.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203620/Bunaim/KIMIA-DATES_hwwyod.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203600/Bunaim/KIMIA-BACK_h5f1rm.jpg"],
    category:"Dates",
    subCategory:"Kimia Dates",
   },
   {
    id:"19",
    title:"Medjool Dates",
    subtitle:"100% NATURAL & ORGANIC",
    price:"150",
    weight:"100 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203821/Bunaim/MEDJOOL_mwf3mc.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203809/Bunaim/MEDJOOL-DATES_tsmzwv.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1695203840/Bunaim/MEDJOOL-BACK_n74sd4.jpg"],
    category:"Dates",
    subCategory:"Medjool Dates",
   },
//    {
//     id:"20",
//     title:"Basmati Rice",
//     subtitle:"100% NATURAL",
//     desc:"Our rice is a true culinary delight, sourced and processed with the utmost care to ensure the finest quality. Each grain embodies the essence of purity and taste, making it the perfect choice for your meals. When you choose our rice, you’re not just selecting a staple; you’re choosing the best quality that adds a touch of excellence to every dish you prepare. Experience the difference with our premium rice today.",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1697214019/Bunaim/Basmati_Rice_rknc73.jpg"],
//     category:"rice",
//     subCategory:"Basmati rice",
//    },
//    {
//     id:"21",
//     title:"Sharbati Rice",
//     subtitle:"100% NATURAL",
//     desc:"Our rice is a true culinary delight, sourced and processed with the utmost care to ensure the finest quality. Each grain embodies the essence of purity and taste, making it the perfect choice for your meals. When you choose our rice, you’re not just selecting a staple; you’re choosing the best quality that adds a touch of excellence to every dish you prepare. Experience the difference with our premium rice today.",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1697214038/Bunaim/Sharbati_Rice_m774ut.jpg"],
//     category:"rice",
//     subCategory:"Sharbati rice",
//    },
//    {
//     id:"22",
//     title:"Jasmine Brown Rice",
//     subtitle:"100% NATURAL",
//     desc:"Our rice is a true culinary delight, sourced and processed with the utmost care to ensure the finest quality. Each grain embodies the essence of purity and taste, making it the perfect choice for your meals. When you choose our rice, you’re not just selecting a staple; you’re choosing the best quality that adds a touch of excellence to every dish you prepare. Experience the difference with our premium rice today.",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1697214038/Bunaim/Jasmine_Brown_Rice_xjjcay.jpg"],
//     category:"rice",
//     subCategory:"Jasmine Brown Rice",
//    },
//    {
//     id:"23",
//     title:"Rice",
//     desc:"Our rice is a true culinary delight, sourced and processed with the utmost care to ensure the finest quality. Each grain embodies the essence of purity and taste, making it the perfect choice for your meals. When you choose our rice, you’re not just selecting a staple; you’re choosing the best quality that adds a touch of excellence to every dish you prepare. Experience the difference with our premium rice today.",
//     subtitle:"100% NATURAL",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695356472/Bunaim/RICE-4_bbvzxn.jpg"],
//     category:"rice",
//     subCategory:"rice",
//    },
//    {
//     id:"24",
//     title:"Chana-Chickpeas",
//     subtitle:"100% NATURAL",
//     desc:"Chana dal is derived from chickpeas and is used to prepare popular dishes like chana masala and chana dal curry. Chickpeas are also commonly used in snacks like roasted chana.",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695356827/Bunaim/PULSE-4_hspbeg.jpg"],
//     category:"Pulses",
//     subCategory:"Chana-Chickpeas",
//    },
//    {
//     id:"25",
//     title:"Masoor-Red Lentis",
//     subtitle:"100% NATURAL",
//     desc:"Masoor is a popular pulse in India known for its rich protein content and quick cooking time. It is commonly used in various dishes like dal and soups.",
//     price:"150",
//     weight:"100 gm",
//     imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1695356827/Bunaim/PULSE-2_lcbdkq.jpg"],
//     category:"Pulses",
//     subCategory:"Masoor-Red Lentis",
//    },
   {
    id:"26",
    title:"Abaja Almonds",
    subtitle:"WHOLE | UNSALTED | NON-GMO | RAW VEGAN FRIENDLY",
    desc:`Almonds are packed with healthy fats, protein, and fiber. They offer a mildly
        sweet, nutty flavor and are often eaten as a nutritious snack or used in various dishes, including
        desserts and salads.`,
    price:"200",
    weight:"250 gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1719164410/Bunaim/taupu3aazqgd6i52bmji.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1719164901/Bunaim/zjmyowsvubdqdfkquemq.jpg"],
    category:"Dry Fruits",
    subCategory:"Almonds",
   },
   {
    id:"27",
    title:"Abaja Cashew",
    subtitle:"WHOLE | UNSALTED |NON-GMO |RAW VEGAN FRIENDLY",
    desc:`Creamy and buttery in texture, cashews are a versatile dry fruit. They are used in
    both sweet and savory dishes, from creamy curries to cashew butter and desserts.`,
    price:"250",
    weight:"300gm",
    imagesUrl:["https://res.cloudinary.com/dwlhxobpc/image/upload/v1719165107/Bunaim/yjmlpa8vzozfanmdtzlc.jpg","https://res.cloudinary.com/dwlhxobpc/image/upload/v1719165185/Bunaim/usrngw6kchszehsfyhc3.jpg"],
    serialNumber:"3",
    category:"Dry Fruits",
    subCategory:"Cashews",
},
]