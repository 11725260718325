import React from 'react'

const OurPresencePage = () => {
  return (<div className='text-center my-8 w-11/12 max-w-[950px] mx-auto'>
    <h2 className='text-3xl font-bold my-5'>Our Offices at:</h2>
    <div className='flex flex-col lg:flex-row items-center justify-center gap-5 my-4'>
    <div className='h-[225px] bg-lime-200 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-3 px-2'> 
        <p className='mt-3'> <span className='font-bold'>Registered Office:</span> N 156/1, F/F Abul Fazal Enclave Part - 1, Okhla, Jamia Nagar, New Delhi, India – 110025</p>
        <p>Contact No. : <a className='underline text-blue-700' href="tel:+919973133607">+919973133607</a></p>
        <p className='mt-3'><span className='font-bold'>Corporate Office:</span> GROUND FLOOR L/S, G-28, KH. NO. 560/419, ABUL FAZAL ENCLAVE-II, SHAHEEN BAGH, New Delhi, 
        South East Delhi, Delhi, 110025 </p>
        <p>Contact No. : <a className='underline text-blue-700' href="tel:+9193197 22117 ">+9193197 22117 </a></p>
    </div>
    <div className='w-full flex flex-col gap-2'>
    <div className=' bg-green-600 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-3 px-2 '>
    <p className='font-bold text-white'>International Offices:</p>
    <p className='font-bold mt-2 text-white text-lg underline'>Sharjah,UAE</p>
        <p className='text-white'>Contact No. : <a className='underline text-white' href="tel:+971 56 640 2368 ">+971 56 640 2368 </a></p>
        
    </div>
    <div className=' bg-green-600 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-3 px-2 '>
    <p className='font-bold text-white'>International Offices:</p>

        <p className='font-bold mt-2 text-white text-lg underline'>Dammam, Saudi Arabia</p>
        <p className='text-white'>Contact No. : <a className='underline text-white' href="tel:+966538363597">+966-53-836-3597</a></p>
    </div>
   </div>
    <div>
      
    </div>
  </div>
  </div>
  )
}

export default OurPresencePage