import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { Products } from "../data/Products";
import ProductCard from "../components/ProductCard";
const ProductPage = () => {
  const { productId } = useParams(); 
  const product = Products.find((Product) => Product.id === productId);
  const similarProduct = Products.filter(
    (item) => item.subCategory === product.subCategory
  ); 
  const ProductYouMayLike = Products.filter(
    (item) => item.category === product.category
  );  

  return (
    <div className="w-11/12 flex flex-col mx-auto my-10">
      <div className="flex flex-col justify-center lg:flex-row">
        <div className="lg:w-[50%] w-[350px]">
          <Carousel
            className="lg:w-[500px] overflow-hidden w-[350px]"
            autoPlay={false}
            infiniteLoop={false}
            dynamicHeight={false}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={true}
            autoFocus={false}
            swipeable={true}
          >
            {product?.imagesUrl.map((img, i) => (
              <div className="lg:w-[400px] lg:h-[450px] h-[320px]" key={i}>
                <img
                  src={img}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="flex flex-col lg:my-4 my-1">
          <div className="flex flex-col gap-2 lg:gap-4">
            <p className="lg:text-5xl text-4xl font-semibold ">
              {product.title}
            </p>
            <p className="text-xl">{product.subtitle}</p>
            {/* <p className="text-3xl text-green-700 font-semibold">
            ₹ {product.price}
          </p> */}
            {/* <p className="text-xl text-gray-500">
            Weight :{" "}
            <span className="text-2xl text-gray-700">{product.weight}</span>
          </p> */}
            <p className="w-11/12 max-w-[600px] text-justify">
              {product?.desc}
            </p>
          </div>
          {/* <div className="flex gap-5 my-10">
             <button className="border-[2px] hover:border-green-900 border-green-700 py-3 px-7 text-xl text-green-800 font-semibold rounded-lg  transition-all duration-300">
            Add to Cart
          </button>
          <button className="bg-green-800 py-3 px-7 text-xl text-white font-semibold rounded-lg hover:bg-green-900 transition-all duration-300">
            Buy Now
          </button> 
          </div> */}
          {
            product?.uses &&
            <div className="my-5"> 
          <p className="text-lg">Uses:</p>
            <ul className="list-disc mx-6">
              {
                product?.uses?.map((item,index)=>(
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
          </div>
          }
        </div>
      </div>
      <div className="flex flex-col gap-y-7 mt-5">
        <h2 className="text-3xl text-center font-semibold">Similar Product</h2>
        <div className="flex flex-wrap gap-3">
          {similarProduct.map((product, index) => (
            <ProductCard product={product} key={index} isSmall={true} />
          ))}
        </div>
      </div>
      <div className="flex flex-col text-center gap-y-7 mt-8">
        <h2 className="text-3xl font-semibold">Product you may like</h2>
        <div className="flex flex-wrap gap-3">
          {ProductYouMayLike.map((product, index) => (
            <ProductCard product={product} key={index} isSmall={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
