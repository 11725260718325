import React, { useRef, useState } from "react"; 
import mailSender from "../../utils/mailSender";
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message:""
  });
  const form = useRef();
  const changeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    mailSender(formData, "template_oo0dbig");
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
  };

  return (
    <div className="h-fit w-11/12 gap-y-5 max-w-[1080px] mx-auto text-md my-16 text-justify flex flex-col justify-center">
      <div className="flex flex-col gap-3">
        <h2 className="text-2xl font-semibold">Contact Us</h2>
        <p className="text-lg">
          For any inquiries, product details, shipping information, pricing, or
          any questions related to our products, we are here to assist you.
          Please don't hesitate to reach out to us at{" "}
          <a className="text-blue-700" href="mailto:info@bunaim.com">
            {" "}
            info@bunaim.com
          </a>
          . Your satisfaction is our priority, and our dedicated team is ready
          to provide you with the information and support you need.
        </p>
      </div>
      <div className="flex gap-5 flex-col items-center lg:flex-row justify-center">
        <div className="flex flex-col w-11/12 lg:w-[450px] items-center justify-center gap-5 mt-6">
          <div className="w-full bg-lime-100 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-6 px-5">
            <h6 className="font-bold py-1">Domestic/Wholesale</h6>
             
            <p className="mt-1">For Orders And inquiry</p>
            <p>Bunaim International Pvt Ltd offers a wide range of premium dry fruits, nuts, dates, confectionery 
            items like candies and chocolates, and beverages. </p>
            <p>
              Contact Us at{" "}
              <a
                className="underline text-blue-700"
                href="mailto:info@bunaim.com"
              >
                info@bunaim.com
              </a>
            </p>
            <p>
              Mobile No. :{" "}
              <a className="underline text-blue-700" href="tel:+919953102658">
              +9193197 22117 
              </a>
            </p>
          </div>
          <div className="w-full border bg-lime-100 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-6 px-5">
            <h6 className="font-bold py-1">International</h6>
            
            <p className="mt-1">For Orders And inquiry</p>
            <p>Bunaim International Pvt Ltd offers a wide range of premium dry fruits, nuts, dates, confectionery 
            items like candies and chocolates, and beverages.</p>
            <p>
              Contact Us at{" "}
              <a
                className="underline text-blue-700"
                href="mailto:sales@bunaim.com"
              >
                sales@bunaim.com
              </a>
            </p>
            <p>
              Mobile No. :{" "}
              <a className="underline text-blue-700" href="tel:+918448030642">
              +9193197 22117 
              </a>
            </p>
          </div>
          
          <div></div>
        </div>
        <form
          onSubmit={submitHandler}
          ref={form}
          className="w-11/12 max-w-[480px] border px-10 bg-gray-300 py-4 rounded-lg shadow-lg shadow-gray-500"
        >
          <div className="flex flex-col my-3 w-full">
            <label htmlFor="name">
              Name <sup className="text-red-700 text-lg">*</sup>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="border-2 px-2 py-1 rounded-lg"
              onChange={changeHandler}
              required
              value={formData.name}
            />
          </div>
          <div className="flex flex-col my-3">
            <label htmlFor="email">
              Email <sup className="text-red-700 text-lg">*</sup>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="border-2 px-2 py-1 rounded-lg"
              onChange={changeHandler}
              required
              value={formData.email}
            />
          </div>
          <div className="flex flex-col my-3">
            <label htmlFor="phone">
              Phone No. <sup className="text-red-700 text-lg">*</sup>
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              className="border-2  px-2 py-1 rounded-lg"
              onChange={changeHandler}
              value={formData.phone}
              required
            />
          </div>
          <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="yourmind">Message<sup className='text-red-700'>*</sup></label>
          <textarea type="text" id='message' name='message'  className="border-2 h-32 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.message} required/>
        </div>
          <button
            type="submit"
            className="text-white bg-green-700 py-2 px-6 rounded-lg hover:bg-green-800 mt-5 transition-all duration-300"
          >
            Submit
          </button>
        </form>
      </div>

      <p className="text-lg font-semibold mt-5">
        Thank you for choosing BUNAIM International Pvt Ltd. We look forward to
        serving you
      </p>
    </div>
  );
};

export default ContactUs;
