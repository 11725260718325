export const FootbarLinks = [
    // {
    //     link:"Track My Order",
    //     subLink:["Where Is my order?"]
    // "Terms of Services"
    // },
    {
        link:"Terms & Policies",
        subLink:["Privacy Policy","Shipping Policy","Return & Refund policy"]
    },
    {
        link:"Help",
        subLink:["Contact Us"]
    },
    {
        link:"About",
        subLink:["About us","Certificates","Our Vision","Our Presence"]
    }, 


]