import React, { useState } from "react";
import { FootbarLinks } from "../data/FootbarLinks";
import { Link } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import { BsInstagram, BsFacebook, BsTwitter, BsWhatsapp } from "react-icons/bs";
const Footer = () => {
  return (
    <>
      <div className="flex flex-wrap mt-20 justify-center gap-12 lg:gap-x-20 lg:px-20 text-lg py-10 bg-lime-100 text-slate-800">
        {FootbarLinks.map((item, index) => (
          <FooterLink item={item} key={index} />
        ))}
      </div>
      <div className="lg:flex hidden bg-lime-100 items-center justify-center text-3xl gap-14 pb-6">
        <a href="https://www.instagram.com/bunaim_food/">
          <BsInstagram />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61552172245955">
          <BsFacebook />
        </a>
        <a href="https://twitter.com/Bunaim_foods">
          <BsTwitter />
        </a>
        <a href="https://wa.me/message/ZCWT6CBGJOLVI1">
          <BsWhatsapp />
        </a>
      </div>
      <div className="text-lg text-white text-center item-center justify-center py-1 bg-slate-700 flex lg:flex-row flex-col">
        <p className="text-md">Design and Develop by</p>{" "}
        <tt className="px-1 lg:text-xl">SQ Software Solution Pvt. Ltd</tt>{" "}
      </div>
    </>
  );
};

export default Footer;

const FooterLink = ({ item }) => {
  const [isClick, setIsClick] = useState(false);
  return (
    <div className="flex flex-col w-full lg:w-fit  mx-6">
      <p className="text-xl lg:text-2xl font-semibold flex items-center justify-between">
        {item.link}{" "}
        <span className="lg:hidden transition-all duration-300">
          {" "}
          {isClick ? (
            <FaMinus onClick={() => setIsClick(false)} />
          ) : (
            <FaPlus onClick={() => setIsClick(true)} />
          )}
        </span>
      </p>
      {item.subLink.length > 0 && (
        <div className="py-4 hidden lg:flex flex-col transition-all duration-300 gap-2">
          {item.subLink.map((subLink, i) => (
            <Link
              key={i}
              to={`${subLink.split(" ").join("-").toLowerCase()}`}
              className="hover:underline "
            >
              {subLink}
            </Link>
          ))}
        </div>
      )}
      {/* For mobile  */}
      {item.subLink.length > 0 && isClick && (
        <div className="py-4 flex flex-col w-full h-full transition-all mx-4 duration-300 gap-2">
          {item.subLink.map((subLink, i) => (
            <Link
              key={i}
              to={`${subLink.split(" ").join("-").toLowerCase()}`}
              className="hover:underline "
            >
              {subLink}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
