import React from "react";

const LocalSupply = () => {
  return (
    <div className="max-w-[1080px] w-11/12 flex flex-col  gap-4 justify-center mx-auto my-14">
    <p className="text-xl">We offer a wide range of quality dry fruits & nuts,dates, spices, rice, pulses, and other food items delivered across India.</p>
      <h2 className="text-xl mx-auto font-bold">Products We Deliver : </h2>
      <p> 
        <span className="font-semibold text-lg">Dry Fruits : </span> Almonds,
        Cashews, Pistachios, Walnuts, Raisins, Figs, Apricots, Pinenuts, Dried
        dates
      </p>
      <p> 
      <span className="font-semibold text-lg">
        Dates : </span>
       Kalmi-safawi Dates,
      Ajwa Dates,
      Medjool Dates,
      Amber Dates,
      Mabroon Dates,
      Sagai Dates, 
      Rabbi Dates,
      Kimia Dates
      </p>
      {/* <p>
        <span className="font-semibold text-lg">
            Spices : </span>
       Turmeric,
      Chilli,
      Black-Pepper,
      Cardamom,
      Clove,
      Coriander,
      Cumin,
      Fenugreek,
      Cinnamon,
      Nutmeg,
      Ginger, 
      Garlic,
      Star
      </p>
      <p>
        <span className="font-semibold text-lg">
        Pulses : </span>
        
       Masoor-Green Lentis,
      Masoor-Red Lentis,
      Urad-Black Gram,
      Toor-Pigeon Peas,
      Moong-Mung Beans,
      Chana-Chickpeas,
      Matar-Green Peas 
      </p>
      <p>
        <span className="font-semibold text-lg">Rice : </span>
        Long Grain White Rice,
      Parboiled Sona masoori,
      Swarna Rice,
      Broken Rice,
      Basmatic Rice  
      </p> */}


      <div className="max-w-[300px] w-11/12 mx-auto bg-lime-100 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg my-10 py-6 px-5">
            <h6 className="font-bold py-1">Contact Us</h6>
             
            <p className="mt-1">For Orders And inquiry</p>
            <p>
              Contact Us at{" "}
              <a
                className="underline text-blue-700"
                href="mailto:info@bunaim.com"
              >
                info@bunaim.com
              </a>
            </p>
            <p>
              Mobile No. :{" "}
              <a className="underline text-blue-700" href="tel:+919953102658">
                +919953102658
              </a>
            </p>
          </div>
    </div>
  );
};

export default LocalSupply;
