import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Products } from "../data/Products";
import ProductCard from "../components/ProductCard";
import ProductNotFound from "../components/ProductNotFound";



const Category = () => {
  const { category } = useParams();
  const item = Products.filter(
    (Products) =>
      (Products.subCategory.toLowerCase().split(" ").join("-") === category) || (Products.category.toLowerCase().split(" ").join("-") === category)
  );
  const [categoryProduct, setCategoryProduct] = useState(item);
  useEffect(() => {
    setCategoryProduct(item);
  }, [category]);
  return (
    <div className="flex flex-row w-11/12 items-center gap-3 mx-auto my-5 flex-wrap">
     
     {
      categoryProduct.length>0 ? (categoryProduct.map((product, index) => (
        <ProductCard key={index} product={product} isSmall={true} />
      ))) : (<ProductNotFound/>)
      
      
      }
    </div>
  );
};

export default Category;


