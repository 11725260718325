import React from 'react'

const Error = () => {
  return (
    <div className='flex flex-col gap-3 justify-center items-center w-full h-[550px] text-4xl text-red-700'>
        <p className='text-3xl'>Error - 404</p>
        <p>Page Not Found</p>
    </div>
  )
}

export default Error