import React from "react";
import { BsInstagram,BsFacebook,BsTwitter,BsWhatsapp } from "react-icons/bs";
const MobileFooter = () => {
  return (
    <div className=" lg:hidden fixed bottom-0 z-[10000] w-full shadow-lg">

    
    <div className="flex bg-lime-100 py-2 items-center justify-center text-3xl gap-14">
      <a href="https://www.instagram.com/bunaim_food/">
        <BsInstagram />
      </a>
      <a href="https://www.facebook.com/profile.php?id=61552172245955">
        <BsFacebook />
      </a>
      <a href="https://twitter.com/Bunaim_foods">
        <BsTwitter />
      </a>
      <a href="https://wa.me/message/ZCWT6CBGJOLVI1">
          <BsWhatsapp />
        </a>
    </div>
    <div className="text-white text-center item-center justify-center py-1 bg-slate-700 flex lg:flex-row flex-col">
        <p className="text-sm">Design and Develop by SQ Software Solution Pvt. Ltd</p>
      </div>
    </div>
  );
};

export default MobileFooter;
