import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { CertificatesLinks } from "../data/CertificatesLink";

const Certificates = () => {
  return (
    <div className="my-6 flex flex-col items-center">
    <h4 className="text-xl lg:text-2xl font-semibold">Certificates</h4>
    <div className="flex gap-5 flex-wrap items-center justify-center my-10">
      
      {CertificatesLinks.map((Certificate, index) => (
        <Zoom key={index}>
          <img alt="Certificate" src={Certificate} className="w-[150px] lg:w-[250px]" />
        </Zoom>
      ))}
    </div>
    </div>
  );
};

export default Certificates;
