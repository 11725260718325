import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="h-fit w-11/12 gap-y-4 max-w-[900px] mx-auto text-md my-16 text-justify flex flex-col justify-center">
      <h4 className="font-semibold text-2xl">Shipping Policy</h4>
      <p>
       <span className="font-semibold"> BUNAIM International LTD</span> is committed to providing our customers with a
        wide range of high-quality food items that are both appealing and safe
        for consumption. We also understand that the shipping and delivery of
        food items is a critical part of the customer experience. This shipping
        policy for food items is designed to provide you with detailed
        information and guidance on the shipment and delivery of our products,
        so as to ensure that your order reaches you when it is most appropriate.
      </p>
      <div>
        <h5 className="font-semibold">Shipping Zones and Availability</h5>
        <p>
          We currently offer shipping services to customers within the Middle
          East countries and India. Please note that certain food items may have
          restrictions on shipping to specific locations due to regulations or
          product availability. You can check the availability of our products
          and shipping options during the checkout process on our website.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Shipping Methods and Timeframes</h5>
        <p>
          We offer several shipping methods to cater to your specific needs. The
          estimated delivery timeframes and associated costs for each method
          will be provided to you during the checkout process. Please note that
          the delivery times are approximate and may vary due to factors beyond
          our control, such as weather conditions, holidays, or carrier delays.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Packaging and Quality Assurance</h5>
        <p>
          We take the utmost care to package our food items securely to prevent
          damage and maintain product freshness during transit. Our packaging
          materials are designed to meet industry standards for food safety and
          hygiene. Additionally, we regularly inspect our products to ensure
          they meet our quality standards before they are shipped to you.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Temperature-Sensitive Items</h5>
        <p>
          There may be specific requirements for the shipment of certain foods,
          e.g. perishables or items requiring refrigeration. To retain the
          proper temperature in transit, we use insulated packaging and packs of
          gel. In order to preserve the quality and safety of these items, they
          need to be stored in accordance with any special storage instructions
          that are given on delivery.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Tracking and Notifications</h5>
        <p>
          A confirmation email containing the tracking number will be sent to
          you as soon as your order is shipped, allowing you to keep an eye on
          its progress. We are also providing you with notifications of the
          status of your shipment, as well as any expected delivery dates and
          potential delays.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Delivery Address</h5>
        <p>
          In the checkout process, please keep in mind that your delivery
          address must be accurate and comprehensive. Due to a customer error,
          we are unable to assume responsibility for an order that has been
          placed at the wrong address. Contact our Customer Support Team as soon
          as possible if you would like to change your delivery address after
          placing an order.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Shipping Fees</h5>
        <p>
          The cost of shipping shall be calculated according to the method
          chosen, destination, and total weight and volume of your order. Before
          you complete your order, you will be able to see the cost of shipping.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Returns and Refunds</h5>
        <p>
          In the unlikely event that you receive damaged or spoiled food items,
          please contact our customer support team within 24 hours of receiving
          your order. We will work with you to resolve the issue, which may
          include issuing a refund or sending a replacement item.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Contact Information</h5>
        <p>
          If you have any questions or concerns about our Shipping Policy for
          Food Items, please don't hesitate to contact our customer support
          team. You can reach us at <a className="text-blue-700" href="mailto:info@bunaim.com"> info@bunaim.com</a> and our dedicated team will
          be happy to assist you.
        </p>
      </div>
      <div>
        <h5 className="font-semibold">Policy Updates</h5>
        <p>
          We may update this Shipping Policy for Food Items from time to time to
          reflect changes in our processes or regulatory requirements. Please
          check this policy periodically for any updates.
        </p>
      </div>
      <div className="font-semibold">
      Thank you for choosing BUNAIM International PVT LTD for your food item purchases. We are committed to providing you with the best quality products and a seamless shipping experience.
      </div>
    </div>
  );
};

export default ShippingPolicy;
