import React from "react";

const AboutusPage = () => {
  return (
    <div className="w-11/12 max-w-[1080px] mx-auto text-md my-5 text-justify lg:text-xl flex flex-col gap-y-6 items-center">
      <h3 className="font-semibold text-2xl">About Us</h3>
      <p>
        <span className="font-semibold">Bunaim International Pvt. Ltd.</span> s
        a premier import-export and distribution company, specializing in
        premium dates, dry fruits, nuts, candies, and beverages. We source the
        finest products from around the world, including KSA, UAE, Jordan, UK,
        China, USA, and more, ensuring top-quality offerings for our customers.
        Serving both B2B and B2C markets across India, we also support small
        city entrepreneurs with franchise opportunities. Our commitment to
        quality and international sourcing makes us a trusted leader in the
        industry.
      </p>
    </div>
  );
};

export default AboutusPage;
