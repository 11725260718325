import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="h-fit w-11/12 gap-y-5 max-w-[900px] mx-auto text-md my-16 text-justify flex flex-col justify-center">
      <h2 className="text-2xl font-semibold">Privacy Policy</h2>
      <p> 
        {" "}
        <span className="font-semibold">
          BUNAIM International PVT LTD{" "}
        </span>{" "}
        ("we," "us," or "our") is committed to protecting the privacy and
        security of our customers' personal information. This Privacy Policy
        outlines how we collect, use, disclose, and safeguard your information
        when you visit our website, use our services, or interact with us in any
        way. By using our services, you consent to the practices described in
        this policy.
      </p>
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Information We Collect</h3>
        <p className="ml-3">We may collect the following types of information:</p>
        <ul className="list-disc ml-8">
          <li>
            Personal Information: This includes your name, contact information
            (such as email address, phone number, and mailing address), and
            payment information (if applicable).
          </li>
          <li>
            Usage Information: We gather data about your interactions with our
            website, products, and services. This may include IP addresses,
            device information, browser type, and pages visited.
          </li>
          <li>
            Cookies and Similar Technologies: We use cookies and similar
            tracking technologies to enhance your experience and collect
            information about your browsing behavior.
          </li>
        </ul>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">How We Use Your Information</h3>
        <p  className="ml-3">We use the information we collect for various purposes, including:</p>
        <ul  className="list-disc ml-8">
          <li>Providing Services: To process orders, deliver products, and provide customer support.</li>
          <li>Personalization: To tailor our offerings and content to your preferences.</li>
          <li>Communication: To send transactional emails, updates, and promotional materials.</li>
          <li>Analytics: To analyze website usage and improve our services.</li>
          <li>Legal Compliance: To comply with legal obligations and protect our rights.</li>
        </ul>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">Sharing Your Information</h3>
        <p  className="ml-3">We may share your information with third parties in the following circumstances:</p>
        <ul className="list-disc ml-8">
          <li>Service Providers: We may share data with trusted service providers who assist us in operating our business.</li>
          <li>Legal Requirements: We may disclose information in response to a legal request or when required by applicable law.</li>
          <li>Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity.</li>
        </ul>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">Your Choices</h3>
        <p  className="ml-3">You have choices regarding the collection and use of your information : </p>
        <ul className="list-disc ml-8">
          <li>Cookies: You can manage your cookie preferences through your browser settings.</li>
          <li>Marketing Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails.</li>
        </ul>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">Security</h3>
        <p className="ml-3">We take reasonable steps to protect your information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet is entirely secure, and we cannot guarantee absolute security.</p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Children's Privacy</h3>
        <p  className="ml-3">Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.</p>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">Updates to this Privacy Policy</h3>
        <p  className="ml-3">We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will post the revised policy on our website with the effective date.</p>
      </div>
      <div  className="flex flex-col gap-2">
        <h3 className="font-semibold">Contact Us</h3>
        <p  className="ml-3">If you have any questions or concerns about our Privacy Policy or your personal information, please contact us at <a className="text-blue-700" href="mailto:info@bunaim.com"> info@bunaim.com</a></p>
      </div>
      <div className="font-semibold text-lg">
      Thank you for choosing BUNAIN International PVT LTD. We value your trust and are committed to protecting your privacy.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
