import React from 'react'
import HeroCarousel from '../components/HeroCarousel'
import NewLaunches from '../components/NewLaunches' 
import BestSeller from '../components/HomeProduct'
import AboutPage from '../pages/AboutusPage'
import { Products } from '../data/Products'
const Home = () => {

  const dryfruits = Products.filter((product)=>product.category === 'Dry Fruits')
  const dates = Products.filter((product)=>product.category === 'Dates')
  // const spices = Products.filter((product)=>product.category === 'Spices')

  return (
    <div className=''>
      <HeroCarousel/>
      <AboutPage/>
      {/* <NewLaunches/> */}
      {/* <Explore/> */}
      <BestSeller product={dryfruits} title={"Dry Fruits"}/> 
      <BestSeller product={dates} title={"Dates"}/> 
      {/* <BestSeller product={spices} title={"Spices"}/>  */}
    </div>
  )
}

export default Home