import React, { useState } from 'react' 
import mailSender from '../utils/mailSender';

const BulkOrderPage = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject:"",
    yourmind:""
  });

  const changeHandler = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    mailSender(formData,"template_dqhd50e") 
    console.log(formData);
    setFormData({
      name: "",
      email: "",
      phone: "",
      subject:"",
      yourmind:""
    })
  };

  return (
    <div className='w-11/12 flex lg:flex-row flex-col justify-evenly items-center mx-auto'>
    <div className='flex flex-col items-center justify-center gap-5 my-4'>
      <div className='max-w-[480px] bg-lime-100 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-2 px-2'>
          <h6 className='font-bold py-2'>Domestic/Wholesale</h6>
          <p>We offer a wide range of quality dry fruits & nuts,dates, and other food items delivered across India.</p>
          <p className='mt-1'>For Orders And inquiry</p>
          <p>Contact Us at <a className='underline text-blue-700' href="mailto:info@bunaim.com">info@bunaim.com</a></p>
          <p>Mobile No. : <a className='underline text-blue-700' href="tel:+919953102658">+919953102658</a></p>
      </div>
      <div className='max-w-[480px] border bg-lime-100 drop-shadow-[5px_11px_4px_rgba(0,0,0,0.20)] rounded-lg py-2 px-2'>
          <h6 className='font-bold py-2'>International</h6>
          <p>We offer premium dry fruits & nuts, and other food items serving customers worldwide with a dedication to quality.</p>
          <p className='mt-1'>For Orders And inquiry</p>
          <p>Contact Us at <a className='underline text-blue-700' href="mailto:sales@bunaim.com">sales@bunaim.com</a></p>
          <p>Mobile No. : <a className='underline text-blue-700' href="tel:+918448030642">+918448030642</a></p>
      </div>
      <div>
        
      </div>
    </div>
      <div className='border max-w-[550px] bg-gray-300 shadow-lg shadow-gray-500 rounded-lg w-full flex flex-col py-4 my-3 items-center justify-center'>
      
      <h2 className='text-lg lg:text-2xl my-2 font-semibold'>Domestic | International Supply</h2>

      <form onSubmit={submitHandler} className='w-11/12 max-w-[500px]'>
        <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="name">Name <sup className='text-red-700'>*</sup></label>
          <input type="text" id='name' name='name'  className="border-2 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.name} required/>
        </div>
        <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="email" >Email <sup className='text-red-700'>*</sup></label>
          <input type="email" id='email' name='email'  className="border-2 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.email} required/>
        </div>
        <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="phone">Phone No. <sup className='text-red-700'>*</sup></label>
          <input type="text" id='phone' name='phone'  className="border-2 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.phone} required/>
        </div>
        <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="subject">Subject <sup className='text-red-700'>*</sup></label>
          <input type="text" id='subject' name='subject'  className="border-2 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.subject} required/>
        </div>
        <div className='flex flex-col gap-1 my-4'>
          <label htmlFor="yourmind">Message<sup className='text-red-700'>*</sup></label>
          <textarea type="text" id='yourmind' name='yourmind'  className="border-2 h-32 px-2 py-1 rounded-lg" onChange={changeHandler} value={formData.yourmind} required/>
        </div>
        <button
          type="submit"
          className="text-white bg-green-700 py-2 px-6 rounded-lg hover:bg-green-800 mt-5 transition-all duration-300"
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  )
}

export default BulkOrderPage
