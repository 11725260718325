import React, { useEffect, useRef, useState } from 'react'
import {RxCross2} from 'react-icons/rx'
import useOnClickOutside from '../hooks/useOnClickOutside';
import { Products } from '../data/Products';
import ProductCard from './ProductCard';


const MobileSearchbar = ({showSearchbar,setShowSearchbar}) => {

    const ref = useRef(null);
    useOnClickOutside(ref, () => {setShowSearchbar(false)});
    const [searchQuery,setSearchQuery] = useState("");
    const [filteredProduct,setFilteredProduct] = useState([]);
    const [isNull,setIsNull] = useState(false);
    useEffect(()=>{
      if(!showSearchbar) {
        setFilteredProduct([]);
        setSearchQuery(""); 
      } 
        setIsNull(false);

    },[showSearchbar,searchQuery])
    const searchHandler = (e) =>{
      if(e.key === "Enter"){
        const item = Products.filter((product) => {
          return (
            product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.subtitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            product.weight.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
        if(item.length===0) setIsNull(true);
        setFilteredProduct(item)
      } 

    }

  return (
    <div  className={`${showSearchbar && "right-0"} fixed z-50 lg:hidden top-0 left-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-30 backdrop-blur-[2px]`}>
    <div
    ref={ref}
      className={`${
        showSearchbar ? "bottom-[0%]" : "bottom-[100%] "
      } w-11/12 mx-auto h-[calc(100%-20px)] fixed transition-all my-[10px] duration-500 ease-linear border shadow-lg rounded-lg bg-white overflow-auto`}
    >
      

      <div className='my-5 flex items-center justify-center gap-5'>
        <input type="text"  className='bg-gray-200 py-2 px-2 w-9/12 rounded-lg' placeholder='Search Something'
          onChange={(e)=>{setSearchQuery(e.target.value)}}
          onKeyUp={searchHandler}
          value={searchQuery}
        />
        <button
        className="text-3xl"
        onClick={() => {setShowSearchbar(false) }}
      >
        <RxCross2 />
      </button>

      </div>

      <div className='flex flex-wrap gap-y-3 justify-evenly w-11/12 mx-auto'>

      { searchQuery.length >0 && isNull && filteredProduct.length === 0 && <div className='text-center mt-5'>No result found for <span className='font-semibold'>"{searchQuery}"</span> </div> }

      {filteredProduct &&
        filteredProduct.map((product, index) => (
          <ProductCard product={product} key={index} isSmall={true} setShowSearchbar={setShowSearchbar} />
        ))}
      </div>
       
    </div>
  </div>
  )
}

export default MobileSearchbar