export const BannerLinksDesktop = [ 
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506504/Bunaim/WEBSITE_BANNER_-_DESKTOP-01_g9hjyx.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506499/Bunaim/WEBSITE_BANNER_-_DESKTOP-02_aznyce.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506500/Bunaim/WEBSITE_BANNER_-_DESKTOP-03_g8ctre.jpg",
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1697304014/Bunaim/WEBSITE_BANNER_-_DESKTOP5-05_lzdik4.jpg",
    //    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506502/Bunaim/WEBSITE_BANNER_-_DESKTOP-04_r9o13o.jpg",
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1697901040/Bunaim/WEBSITE_BANNER_-_DESKTOP_007_gtyunq.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506502/Bunaim/WEBSITE_BANNER_-_DESKTOP-05_trubxk.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1719165658/Bunaim/Candid.png.png",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1719166103/Bunaim/Beverages.png.png",
]
export const BannerLinksMobile = [ 
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506605/Bunaim/WEBSITE_BANNER_MOBILE_PHONE-01_ag67h3.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506605/Bunaim/WEBSITE_BANNER_MOBILE_PHONE-02_u0okle.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506606/Bunaim/WEBSITE_BANNER_MOBILE_PHONE-03_qyel0v.jpg",
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506605/Bunaim/WEBSITE_BANNER_MOBILE_PHONE-04_polbcb.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1696506629/Bunaim/WEBSITE_BANNER_MOBILE_PHONE-05_j1gsa8.jpg",
    // "https://res.cloudinary.com/dwlhxobpc/image/upload/v1697304014/Bunaim/WEBSITE_BANNER_MOBILE_PHONE5-05_pcaqtj.jpg",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1719165862/Bunaim/Candidmobile.png.png",
    "https://res.cloudinary.com/dwlhxobpc/image/upload/v1719166203/Bunaim/Beveragesmobile.png.png",
]