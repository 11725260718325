import React from 'react'

const WhyWeExist = () => {
  return (
    <div  className='h-[500px] w-full text-xl flex items-center justify-center'>
      Why We exist
    </div>
  )
}

export default WhyWeExist
