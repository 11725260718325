import React from "react";

const OurVision = () => {
  return (
    <div className="lg:min-h-[250px] w-11/12 gap-y-4 max-w-[900px] mx-auto text-md my-16 text-justify flex flex-col">
      <h4 className="text-lg">
        <span className="font-semibold">Our Vision : </span>To elevate taste and wellness through exceptional quality.
       
      </h4>
      <p>
        We are dedicated to offering the finest chocolates, candies, beverages,
        dried fruits, dates, and other food items that delight the palate,
        promote health, and prioritize sustainability. By blending tradition
        with innovation, we strive to be your trusted partner in culinary
        excellence and nutrition.
      </p>
    </div>
  );
};

export default OurVision;
