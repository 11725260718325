import React from "react";
import { useParams } from "react-router-dom";
import { Products } from "../data/Products";
import ProductCard from "../components/ProductCard";

const SearchPage = () => {
  const { searchQuery } = useParams();
  // const [product,setProduct] = useState(Products.filter);

  const filteredProduct = Products.filter((product) => {
    return (
      product.title.toLowerCase().includes(searchQuery.toLowerCase().split("-")) ||
      product.subCategory.toLowerCase().includes(searchQuery.toLowerCase().split("-")) ||
      product.category.toLowerCase().includes(searchQuery.toLowerCase().split("-"))
    );
  });
  return (
    <div className="flex flex-col gap-32 mt-10">

      <h2 className="text-5xl mx-auto font-semibold">{filteredProduct.length} result for "{searchQuery}"</h2>

    <div className="flex flex-row w-11/12 gap-5 items-center justify-center mx-auto flex-wrap">
      {filteredProduct &&
        filteredProduct.map((product, index) => (
          <ProductCard product={product} key={index} />
        ))}
    </div>
    </div>
  );
};

export default SearchPage;
