import React, { useRef, useState } from "react";
import { navbarLinkMobile, productCategory,ImportExport } from "../data/navbarCategory";
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import useOnClickOutside from "../hooks/useOnClickOutside";


const Sidebar = ({ setShowSidebar, showSidebar }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {setShowSidebar(false)}); 
  return (
     
      <div
      className={`${
        showSidebar ? "right-0" : ""
      } fixed z-[99999]  top-0 left-0 bottom-0  transition-all duration-500  bg-gray-900 bg-opacity-30 backdrop-blur-[2px]`}
    >
      <div
        ref={ref}
        className={`${
          showSidebar ? "left-2" : "-left-[350px]"
        } w-[250px] h-[calc(100%-10px)] fixed  transition-all duration-500 my-1 shadow-lg rounded-lg bg-white overflow-auto`}
      >
        <button
          className="mt-5 ml-5 text-xl"
          onClick={() => {setShowSidebar(false)}}
        >
          <RxCross2 />
        </button>

        <div className="flex flex-col gap-6 text-black mt-10 mb-6">
          {navbarLinkMobile.map((item, index) => (
            <Category item={item} key={index} setShowSidebar={setShowSidebar} />
          ))}
        </div>
      </div>
    </div> 
  );
};

export default Sidebar;

const Category = ({ item, setShowSidebar }) => {
  const [isClick, setIsClick] = useState(false);
  const [isClickImpexp, setIsClickImpExp] = useState(false);
  return (
    <div className=" w-fit ml-4">
      {item.title === "Product" ? (
        <div>
          <p className="flex items-center text-md  font-semibold hover:text-green-700"
          onClick={()=>setIsClick((prev)=>!prev)}>
            {item.title} <BiChevronDown />
          </p>

          <div className="gap-3 rounded-lg text-black">
            {isClick &&
              productCategory.map((productItem, i) => (
                <div className="flex flex-col mt-3  lg:w-[48%] w-full lg:border-l" key={i}>
                  <p className="font-semibold text-[15px] mx-3 lg:mx-0">
                    {productItem.category}
                  </p>
                  <div className="flex flex-col lg:flex-wrap mx-5 lg:mx-0 gap-1">
                    {productItem.subCategory.map((subCategory, ind) => (
                      <Link
                      onClick={()=>setShowSidebar(false)}
                        key={ind}
                        to={`/category/${subCategory
                          .split(" ")
                          .join("-")
                          .toLowerCase()}`}
                        className="hover:bg-gray-200 text-xs px-2 py-1 mx-3 rounded-md"
                      >
                        {subCategory}
                      </Link>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : ( 
          item.title === "International Trades" ? (
            <div>
            <p className="flex items-center text-md  font-semibold hover:text-green-700"
          onClick={()=>setIsClickImpExp((prev)=>!prev)}>
            {item.title} <BiChevronDown />
          </p>
                {
                 isClickImpexp &&  ImportExport.map((type, i) => (
                  <div className="gap-3 rounded-lg text-black" key={i}>
                    <p className="font-semibold text-[15px] mx-3 lg:mx-0">
                      {type.category} 
                    </p>
                    <div className="flex flex-col lg:flex-wrap mx-5 lg:mx-0 gap-1">
                      {type.subCategory.map((item, ind) => (
                        <Link 
                        
                        key={ind}
                          to={`/category/${item.link.toLowerCase().split(' ').join("-")}`}
                         
                        >
                        <button  className="hover:bg-gray-200 px-3 py-1 mx-3 rounded-md"
                        onClick={()=>{setShowSidebar(false)}} 
                        >

                          {item.title}
                        </button>
                        </Link>
                      ))}
                    </div>
                  </div>
                ))
                 }
              </div>
          ):(<Link
          onClick={()=>setShowSidebar(false)}
            to={`${item?.link.split(" ").join("-").toLowerCase()}`}
            className="flex gap-1 items-center text-md justify-center font-semibold hover:text-green-700"
          >
            {item.title}
          </Link> )
      )}
    </div>
  );
};
