import React from "react";

const RetrunAndRefundPolicy = () => {
  return (
    <div className="h-fit w-11/12 gap-y-3 max-w-[900px] mx-auto text-md my-16 text-justify flex flex-col justify-center">
      <div>
        <h5 className="font-semibold">Refund</h5>
        <p>We accept returns for damaged, defective, or incorrect items within 24 hours of delivered.</p>
      </div>
      <div>
        <h5 className="font-semibold">Refunds</h5>
        <p>A full refund to the original payment method or a replacement will be issued for eligible returns, subject to company approval.</p>
      </div>
      <div>
      <h5 className="font-semibold">Return Criteria</h5>
      <p>To be eligible for a return, the item must be in its original packaging, unused, and accompanied by proof of purchase.</p>
      </div>
      <div>
      <h5 className="font-semibold">Return Process</h5>
      <p>To initiate a return, please contact our customer support team at <a className="text-blue-700" href="mailto:info@bunaim.com"> info@bunaim.com</a>. Returns will only be accepted after approval from the company.</p>
      </div>
      <div>
      <h5 className="font-semibold">Shipping Costs</h5>
      <p>We cover return shipping costs for our errors; customers are responsible for other return shipping costs.</p>
      </div>
      <div>
      <h5 className="font-semibold">Refund Timing</h5>
      <p>Refunds will be processed promptly, with timing varying by payment method.
</p>
      </div>
      <div>
        <h5 className="font-semibold">Policy Updates</h5>
        <p>This policy may change; please visit our website regularly for updates.</p>
      </div>
      <div className="mt-8 font-semibold">
      For any questions or assistance, reach out to our customer support team. Thank you for choosing BUNAIM International PVT LTD.
      </div>
    </div>
  );
};

export default RetrunAndRefundPolicy;
