import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BannerLinksDesktop, BannerLinksMobile } from '../data/HeorBannerLinks';  
const HeroCarousel = () => {
 
  return (
    <div>
      <Carousel className='hidden sm:block relative' autoPlay={true} emulateTouch={true} stopOnHover={false} infiniteLoop={true} dynamicHeight={false} showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
     { 
      BannerLinksDesktop.map((url,index)=>(
        <div key={index}>
          <img src={url} alt='banner'/>
        </div>
      ))
     }
    </Carousel>
    <Carousel className='sm:hidden z-40' autoPlay={true} swipeable={false} emulateTouch={true} stopOnHover={false} infiniteLoop={true} dynamicHeight={false} showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
     { 
      BannerLinksMobile.map((url,index)=>(
        <div key={index} className=''>
          <img src={url} alt='banner' />
        </div>
      ))
     }
    </Carousel>
     
    </div>
  )
}

export default HeroCarousel